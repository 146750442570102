import { render, staticRenderFns } from "./ContactScreen.vue?vue&type=template&id=bac5eb1a&scoped=true&"
import script from "./ContactScreen.vue?vue&type=script&lang=ts&"
export * from "./ContactScreen.vue?vue&type=script&lang=ts&"
import style0 from "./ContactScreen.vue?vue&type=style&index=0&id=bac5eb1a&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bac5eb1a",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTr,QTd,QChip,QBtn,QInnerLoading,QCard,QCardSection,QSkeleton});
