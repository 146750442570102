import { render, staticRenderFns } from "./GenericChart.vue?vue&type=template&id=f33c35c6&scoped=true&"
import script from "./GenericChart.vue?vue&type=script&lang=ts&"
export * from "./GenericChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f33c35c6",
  null
  
)

export default component.exports