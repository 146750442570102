import { render, staticRenderFns } from "./DashboardScreen.vue?vue&type=template&id=4c6bd3ee&scoped=true&"
import script from "./DashboardScreen.vue?vue&type=script&lang=ts&"
export * from "./DashboardScreen.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardScreen.vue?vue&type=style&index=0&id=4c6bd3ee&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6bd3ee",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QCard,QCardSection});
