import { render, staticRenderFns } from "./UserTable.vue?vue&type=template&id=dd3e0216&"
import script from "./UserTable.vue?vue&type=script&lang=ts&"
export * from "./UserTable.vue?vue&type=script&lang=ts&"
import style0 from "./UserTable.vue?vue&type=style&index=0&id=dd3e0216&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTr,QTd,QBtn,QInnerLoading,QSkeleton});
