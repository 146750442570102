import { render, staticRenderFns } from "./TransportReport.vue?vue&type=template&id=8d90ff5e&scoped=true&"
import script from "./TransportReport.vue?vue&type=script&lang=ts&"
export * from "./TransportReport.vue?vue&type=script&lang=ts&"
import style0 from "./TransportReport.vue?vue&type=style&index=0&id=8d90ff5e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d90ff5e",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTable,QSelect,QItem,QItemSection});
