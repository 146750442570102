import { render, staticRenderFns } from "./TransportScreen.vue?vue&type=template&id=0a8d0cb6&"
import script from "./TransportScreen.vue?vue&type=script&lang=ts&"
export * from "./TransportScreen.vue?vue&type=script&lang=ts&"
import style0 from "./TransportScreen.vue?vue&type=style&index=0&id=0a8d0cb6&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QInput,QTable,QTr,QTd,QInnerLoading,QPagination,QPageSticky,QFab,QFabAction,QDialog,QCard,QCardSection,QAvatar,QCardActions});qInstall(component, 'directives', {ClosePopup});
